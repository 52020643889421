.side-bar-col {
  /* max-width: 20%; */
  padding: 0;
}
.css-zyfprb-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: unset !important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}

@media (min-width: 992px) {
  .side-bar-col {
    max-width: 20%;
    padding: 0;
  }
}

.home-container {
  margin-left: 20.5rem;
  /* z-index: 1; */
  margin-top: 74px;
  position: relative;
}

.home-page-heading h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  color: #1e1e1e;
  padding: 40px 0px 16px 0;
  margin: 0;
}

.home-template-card {
  display: flex;
  flex: wrap;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 35px;
  padding: 24px 0;
}
.templates-main-row {
  margin-left: 3.5rem;
}

@media (max-width: 1120px) {
  .home-page-card-row .col-lg-4 {
    flex: 0 0 auto;
    width: 49%;
  }
  .templates-main-row {
    margin-left: 2.5rem;
  }
}

@media (max-width: 948px) {
  .home-page-card-row .col-lg-4 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (width < 990px) {
  .home-template-card {
    row-gap: 30px;
    column-gap: 20px;
  }

  .templates-main-row {
    margin-left: 0;
    padding: 0 20px;
  }
}

@media (width < 974px) {
  .home-template-card {
    row-gap: 30px;
    column-gap: 50px;
    justify-content: center;
  }

  .home-page-heading {
    display: flex;
    justify-content: center;
  }

  /* .templates-main-row {
      margin-left: 0;
      padding: 0 20px;
    } */
}

@media (width < 1250px) {
  /* .side-bar-col{
    max-width: 18%;
  } */
  /* body{
    background-color: red;
  } */

  /* .templates-main-row {
    max-width: 78%;
  } */
}

@media (min-width: 992px) and (width < 1320px) {
  .side-bar-col {
    max-width: 18%;
  }
  .templates-main-row {
    margin-left: 1.6rem;
    max-width: 78%;
    width: 78%;
  }
  .home-template-card {
    column-gap: 20px;
  }
}

@media (min-width: 992px) and (width < 1220px) {
  .side-bar-col {
    max-width: 25%;
  }
  .templates-main-row {
    margin-left: 3.6rem;
    max-width: 60%;
    width: 60%;
  }
  .home-template-card {
    column-gap: 40px;
  }
}

/* @media not all and (min-width: 1120px) and (max-width: 1220px) {
  .side-bar-col{
    max-width: 30%;
  }
  .templates-main-row{
    margin-left: 3.5rem;
    max-width: 70%;
    width: 70%;
  
  
  }
  .home-template-card{
    column-gap: 20px;
  }

} */
