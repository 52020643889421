.submit-success-popup .modal-content {
  width: auto;
}
.top-section-heading {
  display: flex;
  align-items: center;
}

.back-btn-delivery a {
  background-color: inherit;
  color: black;
  border: none;
  border-radius: 0;
  font-size: 30px;
  margin: 0;
  padding: 0;
  /* position: absolute;
         */
  border: none;
}

.platform-select-box {
  box-sizing: border-box;
  width: 81px;
  height: 81px;
  /* left: 88px; */
  /* top: 120px; */
  background: #ffffff;
  border: 1px solid #c6c5c5;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-radius: 5px;
}

.platform-select-box .custom-checkbox {
  display: flex;
  justify-content: end;
}

.platform-select-box .custom-checkbox input {
  margin: 0;
  border-radius: 0;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  /* background: #FFFFFF; */
  border: 1px solid #c6c5c5;
  display: flex;
  justify-content: end;
  border-radius: 5px;
}

.platform-row {
  display: flex;
  align-items: center;
  margin-left: 62px;
  margin-top: 10px;
  gap: 18px;
}

.delivery-title {
  margin-top: 7px;
  text-align: left;
}

.sub-section .sub-title {
  margin-left: 62px;
}

.sub-section h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #1e1e1e;
}

.sub-section p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
}

.delivery-time-section {
  margin: 20px 20px 0 62px;
}

.delivery-time-section .delivery-nature {
  margin: 27px 0px 18px 0px;
}

.delivery-main-section {
  padding: 0 30px 35px 35px;
}
.checkbox-alert {
  border-color: red !important;
}
.mobile {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.platform-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #848484;
  padding-top: 5px;
}

.delivery-main-section .delivery-nature .plan-box {
  box-sizing: border-box;
  width: 115px;
  height: 90px;
  background: #ffffff;
  border: 1px solid #c6c5c5;
  display: flex;
  justify-content: end;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
}

.delivery-plans-group {
  gap: 18px;
}

.delivery-plans-group .plan-box p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #848484;
  font-family: "Poppins";
  margin-top: 2px;
  margin-bottom: 5px;
}

.plan-box .form-check-inline {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0px;
}

.delivery-plans-group .plan-box .plan-containt {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.delivery-plans-group .plan-box input {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 1px solid #c6c5c5;
  border-radius: 0;
  border-radius: 5px;
}

.delivery-data-section {
  padding-bottom: 65px;
}

.delivery-data-section h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #848484;
}

.delivery-data-section .delivery-date {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
}

.delivery-module-selected {
  background: #ffffff;
  border: 1px solid #887cd4;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 25%);
}

/* -----------------------delivery footer start -------------------------------- */

.delivery-page-footer-main {
  border-top: 1px solid #c6c5c5;
  position: fixed;
  bottom: 0;
}

.delivery-page-footer-main .delivery-page-footer-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-delivery-from {
  width: 100%;
  padding: 45px;
  padding-top: 20px;
  padding-bottom: 35px;
}

.project-from-card {
  width: 100%;
  border-radius: 10px;
}
.form-check-input:focus {
  box-shadow: none;
}
.platform-select-box .custom-checkbox input:hover {
  cursor: pointer;
}
.delivery-plans-group .plan-box input:hover {
  cursor: pointer;
}
