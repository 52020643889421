@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "variables.css";

*{
    margin: 0;
    padding: 0;

}


body{
    background: #FFFFFF;
    font-family: "Poppins", "san-serif";
    /* max-width: 1440px; */
     overflow: hidden; 
}

/* @import "login.css";
@import "navBar.css";
@import "module.css";
@import "loader.css"; */
@import "common.css";
@import "auth.css";
@import "serviceCard.css";
@import "home.css";
@import "cardDetails.css";
@import "addCustomFeaturecard.css";
@import "deliveryCard.css";
@import "popups.css";
@import "NotFound.css"






