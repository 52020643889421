.feature-popup-card-main{
    width: 384px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
   
}

.success-popup-card-main{
   border-radius: 0px;
}

.feature-popup-card-main .popup-card-body{
    padding: 16px 40px 30px 40px;

}

/* .popup-close-btn-container{

} */
.popup-card-body {
padding: 40px 30px 20px 30px;
}

.popup-card-body .card-title{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
}

.popup-card-body .card-subtitle{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #848484;
}

.popup-card-body .card-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
}

.popup-card-body .popup-bg-btn{
    margin-top: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.popup-card-body .popup-bg-btn button{
    width: 206px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #C6C5C5;
    border-radius: 7px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #887CD4;
    margin-top: 20px;
}

/* .popup-card-body .popup-cost-section{
    justify-content: center;
} */

.custom-close-btn:hover{
    /* background-color:#b02a37 !important; */
    color: #b02a37 !important;
}
.success-popup-btn:hover{
    background-color: #887CD4 !important;
    color: #FFFFFF !important;
}
.delete-popup-bg-btn button{
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #C6C5C5;
    border-radius: 7px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #887CD4;
    padding-left: 30px;
    padding-right: 30px;
    margin: 10px 13px;
    padding-top: 8px;

}

.delete-popup-bg-btn{
    /* position: absolute;
    bottom: 15%;
    left: 17%; */
    margin-top: 15px;
}


.popup-close-btn-container{
   position: absolute;
   top: 0px;
   right: 0px;
   padding: 10px;
}

.popup-close-btn-container button{
   
    padding: 0px !important;
 }

 .popup-close-btn-container button:hover{
   
    /* background-color: red; */
 }
.popup-close-btn-container .popup-close-btn {
    padding: 0;
    width: 19px;
    height: 19px;
    border: none;
    background: #FFFFFF;
    color: #1E1E1E;
    border: 1px solid #A0A0A0;;
    border-radius: 10px;
    justify-content: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-stroke: 1px white;

}

.succes .modal-content{
    border-bottom: 4px solid #5cb85c;
    border-radius: 0;
}

.alert .modal-content{
    border-bottom: 4px solid #b02a37;
    border-radius: 10px;

}

/* -------------------------------------Confirm Pop-up Css----------------------------------------------- */
/* .confirm-popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
  
  .custom-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .confirm-text {
    margin-bottom: 20px;
  }
  
  .confirm-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .confirm-buttons button {
    padding: 5px 30px;
    border-radius: 0;
    font-size: 16px;
    transition: background-color 0.3s;
    border: none;

  }
  
  .confirm-buttons button.yes-btn {
    background-color: #887CD4;;
    color: white;
  }
  
  .confirm-buttons button.yes-btn:hover {
    background-color: #9f96d8;;
  }
  
  .confirm-buttons button.no-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .confirm-buttons button.no-btn:hover {
    background-color: #c82333;
  }