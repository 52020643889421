
.cost-calculation{
    display: flex;
    align-items: center;
    padding: 18px 28px;

}


.cost-calculation .plan .cost-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* color: #848484; */
    color: var(--text-color);

}

.cost-calculation .plan .feature-cost{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
}

.cost-calculation .operator{
    margin: 0 10px;
    /* color: #848484; */
    color: var(--text-color);

    font-size: 1.5rem;
}

.cost-calculation .vl{
    width: 65px;
    border: 1px solid #C6C5C5;
    transform: rotate(90deg);
}

.delivery-plan-btn button{
    width: 220px;
    height: 36px;
    border-radius: 7px;
    margin-right: 28px;
    font-weight: 700;
    font-size: 14.2257px;
    line-height: 21px;
   
    border: none;
    }

    @media (width < 920px) {

    .cost-calculation .plan .cost-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* color: #848484; */
        color: var(--text-color);
    
    }
    
    .cost-calculation .plan .feature-cost{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #1E1E1E;
    }
    
}
@media (width < 850px) {

.delivery-plan-btn button {
    width: 125px;
    height: 36px;
    border-radius: 7px;
    margin-right: 25px;
    font-weight: 700;
    font-size: 10px;
    line-height: 21px;
    border: none;
}

}
