
.auth-main-card{
    width: 60.25rem;
    height: 36.61rem;
    display: flex;
    flex-direction: row;
    background: linear-gradient(335.39deg, #786ACE 22.86%, #786FB6 41.48%, #9D8EFC 94.7%);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 30px;
}

.auth-container{
    display: flex;
    flex-direction: row;
    justify-content: end;

}
.login-signup{
    text-decoration: none !important;
}

.auth-card{
    /* width: 41 rem; */
    /* height: 36.61 rem; */
    width: 39.5rem;
    height: 36.5rem;
    border-radius: 30px;
    align-items: center;
    margin: '0 auto';
    border: 0;
}




.auth-form-main{
width: 338.08px;
margin-top: 82px;
margin-bottom: 50px;

}

.custom-from-control{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
}

.custom-field input:focus {
    outline: none;
    box-shadow: none;
  }
  .custom-field{
    position: relative;
  }

.auth-card-image{
    position: absolute;
    z-index: 100;
    margin-top: 66px;
}

.form-heading{
    margin-bottom: 50px;
}

.signup-form-heading{
    margin-bottom: 12px;
}

.form-heading-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
/* display: flex; */
align-items: center;
letter-spacing: 0.04em;

color: #000000;
}

.redirect-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12.5521px;
line-height: 19px;

align-items: center;

color: #A0A0A0;
}


.sign-in-button{
    margin-top: 26px;
}

.forgot-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12.5521px;
    line-height: 19px;
    align-items: center;
    color: #786FB6;
    text-decoration: none;
}


.auth-main-card .auth-popup-close-btn-container{
        display: flex;
        justify-content: end;
        margin-top: 13px;
        margin-right: 13px;
        position: absolute;
        right: 0;
        z-index: 1;
    }

    .auth-popup-close-btn-container .popup-close-btn {
        padding: 0;
        width: 32px;
        height: 32px;
        border: none;
        background: #FFFFFF;
        color: #1E1E1E;
        border: 2.3px solid #A0A0A0;
        border-radius: 20px;
        justify-content: center;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-text-stroke: 2.5px white;
    }

    .auth-popup-close-btn-container .popup-close-btn i {
        /* margin-top: 1px; */
    }

    .forgot-inst{
    font-size: 16px;
    line-height: 19px;
    color: #363636;
    margin-bottom: 40px;
    margin-top: 100px;
    }

    /* .auth-popup-close-btn-container .popup-close-btn:hover{
        background-color: #d9534f;
        color: #FFFFFF;
    } */

.divider{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15.0625px;
line-height: 23px;
/* identical to box height */
margin-top: 18px;

display: flex;
align-items: center;
justify-content: center;

color: #919191;
}

.google-auth-container{
    justify-content: center;
    display: flex;
    margin-top: 0px;
    margin-bottom: 19px;
}

.google-auth-container .google-auth-button{
width: 158.99px;
height: 37.66px;
background: #FFFFFF;
border: 0.836806px solid #EAEAEA;
border-radius: 7.53125px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10.0417px;
line-height: 15px;
color: #000000;
/* background: url(../images/authImages/search.png); */
}
.google-auth-button:hover{
    background-color: #F88967 ;
    color:  #FFFFFF;
}

.forgot-button-div{
    margin-bottom: 30px;
}

.sign-up-alert{
    position: absolute;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    left: 18%;
}
@media (max-width: 768px) {
    .auth-card {
      width: 25rem;
    }
    .auth-card-image{
        margin-left: 18px;
    }
    .auth-card-image img{
        width: 300px;
        margin-top: 120px;
    }
    .card-body .auth-form-main{
        width: 100%;
    }
    .auth-main-card {
        width: 40rem;
      }
  }

  /* ---------------------Modals---------------------------- */

  .auth-modal .modal-content{
    display: flex;
    align-items: center;
  }