/* CSS */

.header .navbar-brand {
  height: 73px;
  border-right: 1px solid #c6c5c5;
  padding: 0;
  top: 0;
  position: absolute;
  width: 19.5%;
}

.custom-navbar-brand {
  position: absolute;
  /* width: 94px;
    height: 33px; */
  left: 41px;
  top: 20px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
  color: #1e1e1e;
  text-decoration: none;
}

.custom-vr {
  position: absolute;
  left: 259px;
  top: 0px;
  height: 73px;
}

.dropdown-toggle::after {
  content: none;
}

.navbar-custom-dropdown .profile-logo {
  margin-right: 50px;
  width: 3rem;
  height: 3rem;
}

.dropdown-item-style {
  padding: "0.5rem 1rem";
}

.navbar-custom-dropdown .dropdown-menu {
  margin-left: -100px;
}

/* -----------------Home Sidebar---------------- */

.sidebar {
  /* position: fixed; */
  /* position: absolute; */
  /* top: 72px;  */
  /* left: 0; */
  /* bottom: 0; */
  background-color: #f1f1f1;
  box-sizing: border-box;
  /* width: 260px; */
  background: #ffffff;
  border-right: 1px solid #c6c5c5;
  /* overflow-y: scroll; */
  height: 100%;
}

.sidebar-heading-container {
  height: 4.9rem;
  background: #ffffff;
  /* border: 1px solid #C6C5C5; */
}

.sidebar-heading-container p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  align-items: left;
  color: #848484;

  /* vertical-align: bottom; */

  position: absolute;
  /* width: 102px; */
  /* height: 21px; */
  left: 20px;
  top: 120px;
}

.sidebar-component {
  display: flex;
  justify-content: space-between;
  padding: 15px 17px 7px 20px;
  background: #ffffff;
  cursor: pointer;
}

.sidebar-component-divider {
  margin: 0px;
}

.sidebar-component .form-check-input {
  width: 18px;
  height: 18px;
  /* background: #FFFFFF; */
  border: 1px solid #c6c5c5;
  border-radius: 0px;
  cursor: pointer;
}

.sidebar-component .form-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1e1e1e;
  cursor: pointer;
}

/* -----------------------------MUI- header CSS------------------------------------------- */

.builder-logo {
  width: 65%;
}

.brand-logo-button .MuiButton-label {
  justify-content: left;
}

.main-header .header {
  box-shadow: none;
  padding: 5px 5px;
  box-sizing: border-box;
  width: 100%;
  height: 73px;
  background-color: #ffffff;
  border-bottom: 1px solid #c6c5c5;
}

.main-header .brand-logo-button:hover {
  background-color: inherit;
}

.login-button {
  /* background-color: #887CD4 !important; */
  color: #725ebb !important;
  font-size: 13px !important;
  padding: 7px 14px !important;
}
.login-button:hover {
  background-color: #725ebb !important;
  color: #ffffff !important;
}

.logout-button {
  /* background-color: #FF4D4F !important; */
  color: #dc3545 !important;
  padding: 7px 14px !important;
  font-size: 13px !important;
  border-color: #dc3545 !important;
}

.logout-button:hover {
  background-color: #dc3545 !important;
  color: #ffffff !important;
}
a:hover {
  color: transparent !important;
}
/* -------------------------------Details Page Sidebar-------------------------------------------- */

/* .sidebar-component .custom-control-input:checked~.custom-control-label::before{
    background-color:black;
    background: red;
  }
   */

.form-check-input:checked {
  background: #887cd4;
}

/* -----------------------------------------Media queris---------------------------------------------------- */
@media (max-width: 990px) {
  .header .navbar-brand {
    height: auto;
    border-right: 0px;
    padding: 0;
    top: 0;
    width: 0px;
    position: relative;
  }
  .custom-navbar-brand {
    position: relative;
    left: 10px;
    top: 0px;
  }
  .navbar {
    padding-top: 18px;
  }
}
