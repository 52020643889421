.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #887cd4;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-heading {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .not-found-message {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .not-found-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .not-found-circle {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    animation: not-found-circle-animation 1.5s infinite ease-in-out alternate;
  }
  
  @keyframes not-found-circle-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .not-found-image {
    width: 300px;
    margin-bottom: 30px;
  }
  
  .not-found-suggestion {
    font-size: 16px;
    color: #fff;
  }
  
  .not-found-suggestion a {
    color: #fff;
    text-decoration: underline;
  }
  
  .not-found-suggestion a:hover {
    color: #f7f7f7;
  }
  