:root {
    --main-bg-color: #FFFFFF;
    --sidebar-bg-color: inherit;
    --border-color: #C6C5C5;
    --text-color: #848484;
    --main-color:#887CD4;
    --accordion-icon: url("../images/detailPage/accordion.svg");
    --font-style: normal;
    --font-weight: 400;
    --font-size: 14px;
    --font-family:"Poppins", "san-serif"
  }
  