.custom-button{
border-radius: 7px;
padding: 15px 0 ;
}

.custom-button button{
font-family: 'Poppins';
font-size: 14.2257px;
line-height: 21px;
align-items: center;
height: 36.5px;
}

.close-btn-cls{
    padding: 0px !important;
    margin: 12px !important;
}

/* ---------------------------Font size classes---------------------------------------------------------- */

.custom-field .f-size-14{
    font-size: 14px;
}

.carousel-inner{
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
  
}

.carousel-item{
    height: 100%;
    width: 100%;
}

.carousel-item img{
    object-fit: contain;
width: 100%;
height: 100%;
}

     
     
.imageslider img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}