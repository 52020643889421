.template-card-bottom button{
    box-sizing: border-box;
    width:110px;
    height: 27px;
  
    border-radius: 5px;
   
    color: black;
    font-size: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
  
    line-height: 10px;
    color: #1E1E1E;
    border: 2px solid #725ebb;
}

.template-card-bottom button:hover {
    background: #725ebb;
    color: #FFFFFF;

  }

.main-template-card{
    max-width: 298px;
    width: 298px;
    height: 325px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #C6C5C5;
    box-shadow: 0px 2px 15px 2px rgb(0 0 0 / 10%);
}

.template-card-bottom{
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 2px 0 0 0;
}

.custom-card-lower .custorm-main-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

/* Text dark */

color: #1E1E1E;
}

.template-card-bottom p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #1E1E1E;
    margin: 0;
    margin-top: 5px;
}

.custom-card-upperbody{
    display: flex;
    flex: 1;
    align-items: center;
    
}

.custom-card-upper{
    padding-top: 12px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-main-image{
    height: 146px;
    border-radius: 0;
    object-fit: contain;

}

.custom-card-upperbody .image-card{
    width: 39px;
    height: 39px;
    border-radius: 30px;
}

.custom-card-upperbody .custom-card-title.card-title{
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.6rem;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    left: 14px;
    position: relative;
}

.custom-card-lower{
    padding-top: 8px;
    padding-bottom: 8px;
}

.custom-card-lower .custom-subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1E1E1E;
    margin-left: 0px;
}



/* template feature */

.main-template-card .template-feature{
    display: flex;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: #1E1E1E;
}
  .image-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  