
p ,h1, h2, h3{
    margin: auto;
    padding: 0%;

}

/* --------------------sidebar start--------------------------------------- */

.detail-main-section{
    display: flex;
    background-color: #FFFFFF;
    flex-wrap: wrap;
    padding-bottom: 104px;

    /* gap: 5px; */

}

.detail-feature-sidebar{
    height: 100%;
    overflow-y: auto;
    background-color: inherit;
    flex: 20.5%;
    max-width: 20%;
    /* min-width: 20%; */
    border-right: 1px solid #C6C5C5;

}
@media  (max-width: 1000px) {
    .detail-feature-sidebar{
        flex: 100%;
        max-width: 100%;
    }

  }

.template-feature-title{
    height: 62px;
    border-bottom: 1px solid #C6C5C5;
}

.template-feature-title p{
    padding-left: 20px;
    padding-top: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    align-items: left;
    color: #848484;
}

.custom-accordion .accordion-collapse{

}

/* .accordion-button:not(.collapsed) . */

.feature-details-popup .modal-content{
    width: auto;
    border-radius: 30px;
}
.feature-details-popup .modal-content .card-wrapper {
    min-width: 500px;
}

.detail-feature-sidebar .custom-accordion .accordion-item{
    /* padding-left: 15px; */

}
.accordion-button:focus{
    border-color: #C6C5C5;
    box-shadow:none;
}
.custom-accordion .accordion-item{
    border-bottom: 1px solid #C6C5C5;

}

.detail-feature-sidebar .custom-accordion .feature-detail-button{
    background: white;
    color: black;
    font-size: 15px;
    border: none;
    padding: 0;

}

.custom-accordion .accordiaon-subbody{
    /* padding-left: 15px; */
}

.custom-accordion .accordion-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #C6C5C5;
    padding-right: 10px;
}

.custom-accordion .accordion-body .feature-checkbox{
    width: 24px;
    display: inline;
    margin-left: 12px;
    cursor: pointer;
}
.accordion-body .feature-checkbox input{
    cursor: pointer;
}
.accordion-button:not(.collapsed)::after{
    position: absolute;
    right: 10px;
    top: 4px;
}

.accordion-button.collapsed::after{
    position: absolute;
    right: 5px;
    top: 15px;
}


/* .accordion-button.collapsed {
    background: blue
  } */


  .custom-accordion-body h5{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    margin: 0;
  }
  .custom-accordion-body .accord-actions{
  width: 50px;
  }

.custom-accordion .accordion-body .form-check-input[type=checkbox] {
    background-image: url("../images/detailPage/plus.svg") ;
    border-radius: 0;
    background-size: 10px;

}

.custom-accordion .accordion-body .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") ;
    background-size: 15px;

}
.custom-accordion{
    height: calc(100vh - 140px);
    margin-right: 10px;
    margin-left: 10px;
}

  .accordion-button.collapsed::after {
    background-size: 15px;
    background-image: url("../images/detailPage/accordion.svg") ;
  
}

.accordion-button:not(.collapsed)::after {
    background-size: 15px;
    background-image: url("../images/detailPage/accordion.svg") ;
  
}

.detail-feature-sidebar .custom-accordion button{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}






/* --------------------sidebar end--------------------------------------- */

/* -------------------middle theme detail section start---------------------- */

.theme-detail-middle-section{
    height: auto;
    background-color: inherit;
    flex: 51%;
    overflow-y: auto;
position: relative;
}

.theme-detail-middle-section .back-button{
  
    position: absolute;
    z-index: 99;

} 

.theme-detail-middle-section .back-button a{
    /* background: #FFFFFF; */
    background-color: inherit;
    color: black;
   
    border-radius: 0;
    font-size: 30px;
    margin: 0;
    padding: 0;
    /* position: absolute;
     */
}


.theme-image-section{
    height: 300px;
    width: 100%;

}



.theme-detail-middle-section .theme-containt{
    /* margin-left: 85px;
    margin-top: 27px; */
    
    padding: 15px 30px 80px 30px;
}


/* -------------------middle theme detail section end---------------------- */



/* ------------------detail custom feature section start-------------------- */
.detail-custom-feature-bar{
    height: auto;
    background-color:inherit;
    flex: 25%;
    box-sizing: border-box;
    /* width: 380px; */
    /* top: 73px; */
    background: #FFFFFF;
    border-left: 1px solid #C6C5C5;
    /* padding: 0 17px 0 35px; */

}
/* .feature-section{
   
  } */

  .feature-header{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* margin: 30px 17px 30px 37px; */
    margin: 20px 0;
    margin-left: 15px;
    margin-right: 15px;
  }

  .feature-count{
    font-weight: 600 !important;
    margin-left: 5px;
  }


  .sub-section .feature-header p{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* color: #848484; */
    color: var(--text-color);

    margin: 0;
    display: flex;
    align-items: center;
 
  }

  .feature-header button{
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    width: 163px;
    height: 34px;
    border: 1px solid #C6C5C5;
    border-radius: 7px;

  }

  .feature-header .custom-feature-btn{
    line-height: 14px;
  }

  .feature-section .feature-divider{
    width: 100%;
    height: 0px;
    border: 1px solid #C6C5C5;
    margin: auto;
  }

  .feature-container{
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .feature-container .feature-actions{
    width: 62px;
    text-align: right;
  }

  .feature-container .title-price-div{
    width: calc(100% - 62px);
  }

  .feature-container .title-price-div .feature-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    margin: auto;
  }

  .feature-container .title-price-div .feature-price{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    /* color: #848484; */
    margin: auto;

  }

  .feature-container button{
    /* border: 0; */
    box-sizing: border-box;
    background: #FFFFFF;
    border-left: 1px solid #C6C5C5;
    /* color: #848484; */
    color: var(--text-color);
    /* color: red; */

    border: none;
    font-size: 20px;
    margin-right: 22px;
    padding: 5px;
  }
  
/* --------------------------detail custom features end----------------------------- */



/* --------------------------template cost estimation footer start------------------------ */
.cost-estimation-footer-main{
    display: flex;
    background: var(--main-bg-color);
    justify-content: end;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
}

.cost-estimation-footer-main .cost-estimation-footer-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* max-width: 1080px; */
    background: inherit;
    border: 1px solid #C6C5C5;
    border-radius: 10px;
    position: absolute;
    height: 80px;
    bottom: 3px;
    right: 3px;
}

.detail-container{
    position: absolute;
    width: 452.54px;
    height: 285.65px;
    top: 74px;
    filter: drop-shadow(-0.776224px 2.32867px 10.0909px rgba(0, 0, 0, 0.1));
}


.detail-container .back-button a{
    background: #FFFFFF;
    color: black;
    border: none;
    border-radius: 0;
    font-size: 30px;
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    position: absolute;
    border: none;
}

.detail-page-image{
    justify-content: center;
    display: flex;
    margin-left: 180px;
}

.detail-page-image img{
    position: absolute;
    width: 100%;
    height: 100%;
    filter: drop-shadow(-0.776224px 2.32867px 10.0909px rgba(0, 0, 0, 0.1));
    /* margin-left: 100px; */
}

/* @media only screen and (max-width: 1140px) {
    .detail-page-image img {
      width: 80%;
      height: 80%;
    }
  } */

.detail-containt-section{
    position: absolute;
    top: 93%;
    margin-left: 34px;
    margin-top: 30px;
    width: 129%;
}

.template-title h1{
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: #1E1E1E;
    margin: auto;
}

.template-title .categoty{
    /* font-family: 'Poppins'; */
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1E1E1E;
    padding: 0;
    margin: 0;
}

.template-title .cost-title{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    /* color: #848484; */
    color: var(--text-color);
    margin-top: 9px;
}

.template-title .cost{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  
    color: var(--text-color);
}

.template-title .detail{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
margin-top: 10px;

/* color: #848484; */

}

/* 
.delivery-footer .delivery-plans{
    height: 104px;
    width: 80.7%;
    bottom: 0;
    position: fixed;
    right: 0;
    box-sizing: border-box;
    background: #FFFFFF;
    border-top: 1px solid #C6C5C5;
    padding-bottom: 50px;
} */



/* .delivery-plan-btn {
    display: flex;
    align-items: center;
} */

@media screen and (max-width: 768px) {
    .feature-details-popup .modal-content .card-wrapper {
        min-width: 330px;
    }   
}