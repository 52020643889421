.spinner{
    position: fixed;
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    /* opacity: 0.5; */
    /* filter: blur(2px); */
    /* justify-content: center; */
    align-items: center;
    background: none;
    z-index: 1;
    backdrop-filter: blur(2px);
}


.preloader {
    position: relative;
    margin: 50px auto;
    width: 100px;
    height: 100px;
  }
  .load-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #fff;
    border-top-color:var(--main-color);
    border-bottom-color:var(--main-color);
    animation: load1 1s linear infinite;
  }
  .load-2 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-width: 10px;
    border-style: solid;
    border-color: #fff;
    border-top-color: #000;
    border-bottom-color: #000;
    animation: load1 1.5s linear infinite;
  }
  .load-3 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 25px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #fff;

    border-top-color: var(--main-color);
    border-bottom-color: var(--main-color);
    animation: load1 2s linear infinite;
  }
  .load-4 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 32px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #FFFFFF;
    border-top-color: #000;
    border-bottom-color: #000;
    background-color:var(--main-color);
    animation: load1 2.5s linear infinite;
  }
  .load-5 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 35px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color:var(--main-color);
  }
  
  @keyframes load1 {
    50% {
    }
    100% {
      transform: rotate(360deg);
    }
  }