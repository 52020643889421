.custom-feature-card{

    width: -webkit-fill-available;
    height: auto;
  
}


.add-custom-feature-main .modal-content {
    align-items: center;
    border: none;
    width: 100%;
    max-width: 800px;
    height: 430px;
    background: #FFFFFF;
    border-radius: 10px;
    position:relative;
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    ;
}
.custom-feature-add-btn:hover{
    background-color: #887CD4 !important;
    color: #FFFFFF !important;
}

.modal-content .close-btn{
   
    position: absolute;
    top: 0px;
    right: 0px;;
}



.custom-feature-card .custom-feature-card-body{
    /* padding: 0px 27px 17px 40px; */
    padding: 5px;
}

.feature-container .custom-feature-detail-button{
    background: white;
    color: black;
    font-size: 15px;
    border: none;
    padding: 0;
    /* margin-left: -180px; */
}

.feature-container .custom-feature-detail-button:hover{
    background: white;
}
.feature-container .delete-icon:hover{
    color: red;
}

.custom-feature-card-body h3{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    margin-top: 20px;
}

.custom-feature-card-body p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
    margin-top: 20px;

}


.custom-feature-card-body .create-btn{

    display: flex;
    justify-content: center;
}

.custom-feature-card-body .create-btn button{
    width: 206px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #C6C5C5;
    border-radius: 7px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #887CD4;
}